import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import * as styles from './index.module.scss'

const Pagination = ({ current, total, currentUrl }) => {
  useEffect(() => {
    if (current !== 1) {
      document.getElementById('postListing').scrollIntoView()
    }
  })

  if (total < 2) {
    return null
  }

  const pages = Array(total).fill().map((i, index) => {
    const path = index + 1 === 1 ? currentUrl : `${currentUrl}/${index + 1}`

    return (
      <Link key={index} to={path} className={styles.page} activeClassName={styles.active}>{index + 1}</Link>
    )
  })

  const prevUrl = current === 2 ? currentUrl : `${currentUrl}/${current - 1}`

  return (
    <section className={styles.pagination}>
      {(current > 1 &&
        <Link to={prevUrl} className={styles.prev}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" fill="currentColor" />
          </svg>
        </Link>
      )}
      {pages}
      {(current < total &&
        <Link to={`${currentUrl}/${total}`} className={styles.next}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="currentColor" />
          </svg>
        </Link>
      )}
    </section>
  )
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  currentUrl: PropTypes.string.isRequired
}

export default Pagination
