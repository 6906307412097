import * as styles from './index.module.scss';

import Layout from '../../components/layout/index';
import Pagination from '../../components/pagination';
import PostsListing from '../../components/posts/listing';
import PropTypes from 'prop-types';
import React from "react";
import SectionsRenderer from '../../components/sections/renderer';
import Seo from '../../components/seo';
import { graphql } from "gatsby";
import { pathTo } from '../../routes';

const Posts = ({ data: { page, pressreleases }, pageContext: { numPages, currentPage } }) => {
  const { title, metaTitle, metaDescription, sharingTitle, sharingDescription, sharingImage, metaKeywords, sections, hasImageHeader } = page

  const posts = pressreleases.edges.map(node => node.node);

  return (
    <Layout key={currentPage} hasImageHeader={hasImageHeader}>
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDescription={metaDescription && metaDescription.metaDescription}
        sharingTitle={sharingTitle}
        sharingDescription={sharingDescription && sharingDescription.sharingDescription}
        sharingImage={sharingImage && sharingImage.file && sharingImage.file.url}
        currentUrl={pathTo(page)}
        keywords={metaKeywords}
      />
      <h1 className={styles.title}>{title}</h1>
      <div>
        <SectionsRenderer sections={sections}/>
        <div id="postListing">
          <PostsListing items={posts} wide={true}/>
        </div>
        <Pagination current={currentPage} total={numPages} currentUrl={pathTo(page)}/>
      </div>
    </Layout>
  )
}

Posts.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.object,
    sharingTitle: PropTypes.string,
    sharingDescription: PropTypes.object,
    sharingImage: PropTypes.object,
    metaKeywords: PropTypes.array,
    sections: PropTypes.array,
  })
}

export default Posts

export const postListQuery = graphql`
  query PostListQuery($id: String!, $skip: Int!, $limit: Int!) {
    page: contentfulPage(id: { eq: $id }) {
      title
      slug
      metaTitle
      hasImageHeader
      layout
      metaDescription {
        metaDescription
      }
      sharingTitle
      sharingDescription {
        sharingDescription
      }
      sharingImage {
        title
        description
        contentful_id
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      metaKeywords: keywords
      requiresAuthenticatedUser
      sections {
        __typename
        ... on ContentfulAdmaMembers {
          id
          title
          artistsPerPage
        }
        ... on ContentfulCategory {
          ...Category
        }
        ... on ContentfulJudgesGrid {
          ...JudgesGrid
        }
        ... on ContentfulGateway {
          ...Gateway
        }
        ... on ContentfulGallery {
          ...Gallery
        }
        ... on ContentfulPageHeader {
          ...PageHeader
        }
        ... on ContentfulPostListing {
          ...PostListing
        }
        ... on ContentfulPressdownload {
          ...Pressdownload
        }
        ... on ContentfulQuote {
          ...Quote
        }
        ... on ContentfulText {
          ...Text
        }
        ... on ContentfulYouTubePlayer {
          ...YouTubePlayer
        }
        ... on ContentfulWebsiteHeader {
          ...WebsiteHeader
        }
      }
    }

    pressreleases: allContentfulPost(limit: $limit, skip: $skip, sort: {fields: publishDate, order: DESC}) {
      edges {
        node {
          ...PostListingItem
        }
      }
    }
  }
`;
